
import { Vue, Component, Watch } from 'vue-property-decorator'
import {
  Article, ArticlePageErrata, ArticlePageDefault,
  ArticlePageContainer, ArticlePageSharedReview,
} from '@/components/article-page'
import { ArticlesAPI } from '@/api'

@Component({
  components: {
    ArticlePageContainer,
    ArticlePageDefault,
    ArticlePageErrata,
    ArticlePageSharedReview,
  },
})
export default class RelayStation extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  loading = false
  articleId: string|null = null
  article: Article | null = null

  mounted() {
    document.title = 'Article - MathSciNet'
  }

  //
  // WATCHERS
  //
  @Watch('$route', { immediate: true })
  onRouteChanged() {
    this.checkURLParams()
    this.fetchData()
  }

  checkURLParams() {
    this.articleId = this.$route.query.mr ? (this.$route.query.mr as string).replace('MR', '') : null
    // this.articleId = Number.isNaN(+articleId) ? -1 : +articleId
  }

  // relationType(article: Article | null): string {
  //   return article !== null && article.relation && article.relation.relationType
  //     ? article.relation.relationType.toLowerCase().replace(/\s/, '_')
  //     : ''
  // }

  async fetchData() {
    if (this.articleId === null) return

    this.loading = true
    this.article = await ArticlesAPI.getArticlePublic(this.articleId)
    if (this.article !== null) {
      document.title = `MR${this.article.paperId.toString().padStart(7, '0')} - MathSciNet`
    }
    this.loading = false
  }
}
